export const environment = {
    production: true,
    apiKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inp1a2NscXNwbmR5c2VtdnZpaGptIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTg0MzY3MjMsImV4cCI6MjAxNDAxMjcyM30.0coKg0MtwT-Ipho6wb3JK5kxGU3xRF01D2_YhGfDvos",
    apiUrl: "https://zukclqspndysemvvihjm.supabase.co",
    shortName: "VoS",
    longName: "Vororchester",
    showTeachers: true,
    symphonyImage: true,
    withExcuses: false,
    withSignout: false,
    isChoir: false,
};