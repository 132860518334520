// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-modal {
  --width: 290px;
  --height: 382px;
  --border-radius: 8px;
}

ion-modal ion-datetime {
  height: 382px;
}

.bottom-padding {
  height: 40px;
  width: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/people/person/person.page.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;EACA,oBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;AACF","sourcesContent":["ion-modal {\n  --width: 290px;\n  --height: 382px;\n  --border-radius: 8px;\n}\n\nion-modal ion-datetime {\n  height: 382px;\n}\n\n.bottom-padding {\n  height: 40px;\n  width: auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
