// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-modal.dateModal {
  --width: 290px;
  --height: 382px;
  --border-radius: 8px;
}

ion-modal.dateModal ion-datetime {
  height: 382px;
}`, "",{"version":3,"sources":["webpack://./src/app/history/history.page.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,eAAA;EACA,oBAAA;AACJ;;AAEE;EACE,aAAA;AACJ","sourcesContent":["ion-modal.dateModal {\n    --width: 290px;\n    --height: 382px;\n    --border-radius: 8px;\n  }\n\n  ion-modal.dateModal ion-datetime {\n    height: 382px;\n  }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
